import * as React from "react";

const Commercial2 = (props) => {
  return (
    <div class="Commercial 2">
      {" "}
      <div class="hero mt-20 items-center pb-10">
        <div class="mx-auto max-w-7xl">
          <div class="flex justify-center items-center">
            <div class="w-1/2">
              <nav class="opacity-100 flex" aria-p="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div>
                      <a href="/" class="text-white hover:text-gray-200">
                        <svg
                          class="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="sr-only">Home</span>
                      </a>
                    </div>
                  </li>

                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Commercial1";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        Commercial
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button class="pointer-events-none ml-4 text-sm font-medium text-white hover:text-gray-200">
                        Energy
                      </button>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Commercial
              </h1>
              <p class="mt-6 text-2xl tracking-tight font-extrabold text-yellow-200 ">
                Tell us about your business energy usage.
              </p>
              <p class="mt-2 text-base text-white sm:text-xl lg:text-lg xl:text-xl">
                If you don't use a certain energy type please leave it blank,
                you must input a value in at least 1 energy input field to
                continue.
              </p>{" "}
              <div class="w-full electricity mt-8 pb-4 space-y-6 ">
                <div>
                  <div class="mt-12 flex items-center">
                    <svg
                      class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M14 19h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm0 2h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm.25 2h-4.5l1.188.782c.154.138.38.218.615.218h.895c.234 0 .461-.08.615-.218l1.187-.782zm3.75-13.799c0 3.569-3.214 5.983-3.214 8.799h-1.989c-.003-1.858.87-3.389 1.721-4.867.761-1.325 1.482-2.577 1.482-3.932 0-2.592-2.075-3.772-4.003-3.772-1.925 0-3.997 1.18-3.997 3.772 0 1.355.721 2.607 1.482 3.932.851 1.478 1.725 3.009 1.72 4.867h-1.988c0-2.816-3.214-5.23-3.214-8.799 0-3.723 2.998-5.772 5.997-5.772 3.001 0 6.003 2.051 6.003 5.772zm4-.691v1.372h-2.538c.02-.223.038-.448.038-.681 0-.237-.017-.464-.035-.69h2.535zm-10.648-6.553v-1.957h1.371v1.964c-.242-.022-.484-.035-.726-.035-.215 0-.43.01-.645.028zm-3.743 1.294l-1.04-1.94 1.208-.648 1.037 1.933c-.418.181-.822.401-1.205.655zm10.586 1.735l1.942-1.394.799 1.115-2.054 1.473c-.191-.43-.423-.827-.687-1.194zm-3.01-2.389l1.038-1.934 1.208.648-1.041 1.941c-.382-.254-.786-.473-1.205-.655zm-10.068 3.583l-2.054-1.472.799-1.115 1.942 1.393c-.264.366-.495.763-.687 1.194zm13.707 6.223l2.354.954-.514 1.271-2.425-.982c.21-.397.408-.812.585-1.243zm-13.108 1.155l-2.356 1.06-.562-1.251 2.34-1.052c.173.433.371.845.578 1.243zm-1.178-3.676h-2.538v-1.372h2.535c-.018.226-.035.454-.035.691 0 .233.018.458.038.681z" />
                    </svg>
                    <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                      Electricity
                    </h1>
                    <p class="text-xs pl-2 pt-2 sm:text-sm text-white">
                      - How much do you use annually?
                    </p>
                  </div>

                  <div class="mt-6 relative rounded-md">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      name="userElectricity"
                      onChange={props.addData}
                      class="border border-emerald-900 rounded-md py-4 px-14 focus:ring-light-yellow block w-full text-gray-500 mt-1 block w-full pl-3 pr-10 text-lg focus:outline-none"
                      placeholder="2,000
                        "
                    />
                    <div class="absolute inset-y-0 right-0 flex items-center">
                      <select
                        name="userElectricityUnit"
                        onChange={props.addData}
                        class="focus:ring-emerald-500 focus:border-emerald-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md focus:outline-none"
                      >
                        <option value="kWh" default selected>
                          kWh
                        </option>

                        <option value="MWh">MWh</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {props.data.uetGreen === "true" ? (
                <div class="w-full natural gas border-t mt-8 pb-4 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M19 6.734c0 4.164-3.75 6.98-3.75 10.266h-1.992c.001-2.079.996-3.826 1.968-5.513.913-1.585 1.774-3.083 1.774-4.753 0-3.108-2.518-4.734-5.004-4.734-2.482 0-4.996 1.626-4.996 4.734 0 1.67.861 3.168 1.774 4.753.972 1.687 1.966 3.434 1.967 5.513h-1.991c0-3.286-3.75-6.103-3.75-10.266 0-4.343 3.498-6.734 6.996-6.734 3.502 0 7.004 2.394 7.004 6.734zm-4 11.766c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm0 2c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm-1.701 3.159c-.19.216-.465.341-.752.341h-1.094c-.287 0-.562-.125-.752-.341l-1.451-1.659h5.5l-1.451 1.659zm2.013-18.767c-.489.287-.854.713-1.06 1.312-.556 1.609.373 2.928-.235 4.432-.355.875-1.104 1.33-1.889 1.41-.15 1.551.059 3.702.545 4.953h-1.113c-.581-2.322-.268-6.441 1.025-8.764-.787.603-1.564 2.254-1.79 3.567-2.738-1.642-.659-6.91 4.517-6.91z" />
                      </svg>

                      <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                        Natural Gas
                      </h1>
                      <p class="text-xs pl-2 pt-2 sm:text-sm text-emerald-200">
                        - Thank you for being a Green Gas Customer!
                      </p>
                    </div>

                    <div class="opacity-50 mt-6 relative rounded-md">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="userNaturalGas"
                        onChange={props.addData}
                        disabled
                        class="border border-emerald-900 rounded-md py-4 px-14 focus:ring-light-yellow block w-full text-gray-500 mt-1 block w-full pl-3 pr-10 text-lg focus:outline-none"
                        placeholder="2,000"
                      />
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <select
                          name="userNaturalGasUnit"
                          onChange={props.addData}
                          disabled
                          class="focus:ring-emerald-500 focus:border-emerald-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md focus:outline-none"
                        >
                          <option value="Therm" default selected>
                            Therm
                          </option>
                          <option value="CCF">CCF</option>
                          <option value="MCF">MCF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="w-full natural gas border-t mt-8 pb-4 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M19 6.734c0 4.164-3.75 6.98-3.75 10.266h-1.992c.001-2.079.996-3.826 1.968-5.513.913-1.585 1.774-3.083 1.774-4.753 0-3.108-2.518-4.734-5.004-4.734-2.482 0-4.996 1.626-4.996 4.734 0 1.67.861 3.168 1.774 4.753.972 1.687 1.966 3.434 1.967 5.513h-1.991c0-3.286-3.75-6.103-3.75-10.266 0-4.343 3.498-6.734 6.996-6.734 3.502 0 7.004 2.394 7.004 6.734zm-4 11.766c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm0 2c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm-1.701 3.159c-.19.216-.465.341-.752.341h-1.094c-.287 0-.562-.125-.752-.341l-1.451-1.659h5.5l-1.451 1.659zm2.013-18.767c-.489.287-.854.713-1.06 1.312-.556 1.609.373 2.928-.235 4.432-.355.875-1.104 1.33-1.889 1.41-.15 1.551.059 3.702.545 4.953h-1.113c-.581-2.322-.268-6.441 1.025-8.764-.787.603-1.564 2.254-1.79 3.567-2.738-1.642-.659-6.91 4.517-6.91z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                        Natural Gas
                      </h1>
                      <p class="text-xs pl-2 pt-2 sm:text-sm text-white">
                        - How much do you use annually?
                      </p>
                    </div>

                    <div class="mt-6 relative rounded-md">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="userNaturalGas"
                        onChange={props.addData}
                        class="border border-emerald-900 rounded-md py-4 px-14 focus:ring-light-yellow block w-full text-gray-500 mt-1 block w-full pl-3 pr-10 text-lg focus:outline-none"
                        placeholder="2,000"
                      />
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <select
                          name="userNaturalGasUnit"
                          onChange={props.addData}
                          class="focus:ring-emerald-500 focus:border-emerald-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md focus:outline-none"
                        >
                          <option value="Therm" default selected>
                            Therm
                          </option>
                          <option value="CCF">CCF</option>
                          <option value="MCF">MCF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div class="button-group pt-8 mt-8 flex justify-start ">
                <button
                  onClick={() => {
                    const newPosition = "Commercial1";
                    props.onChange(newPosition);
                  }}
                  class="w-1/2 border border-2 border-pacGreen flex items-center justify-center px-10 py-5  text-base font-medium rounded-md text-pacBlue bg-pacGreen hover:opacity-90 md:py-4 md:text-lg md:px-10 "
                >
                  Previous
                </button>

                {props.electricity === 0 && props.naturalGas === 0 ? (
                  <button class="pointer-events-none border border-2 border-white w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white hover:opacity-90 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3 opacity-50">
                    Continue
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      const newPosition = "Commercial3";
                      props.onChange(newPosition);
                    }}
                    class="border border-2 border-white w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white  hover:bg-white hover:text-pacBlue md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3"
                  >
                    Continue
                  </button>
                )}
              </div>
            </div>
            <div class="pl-10 w-1/2  relative"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commercial2;
